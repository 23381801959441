export const variables = {
    //API_URL: "https://localhost:7042/api/",
    API_URL: "https://api.homexbuilder.com/api/",
    //projectImgPath: "https://localhost:7042/uploads/",
    projectImgPath: "https://api.homexbuilder.com/uploads/"
};


export const validEmail = new RegExp(
    '^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$'
);