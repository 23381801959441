import * as React from "react";
import * as ReactDOM from "react-dom";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { variables } from '../Variables';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { Dropdown, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { MultiSelect } from "react-multi-select-component";
import { setSidebarCollapsed } from "../features/SidebarCollapseSlice";

import { ProjectUpload } from "./ProjectUpload";

import filterIcon from '../assets/images/filter-icon.svg';
import plusIcon from '../assets/images/plus.svg';
import collapseIcon from '../assets/images/collapse.svg';
import expandIcon from '../assets/images/expand.svg';
import logOutIcon from '../assets/images/log-out.svg';
import placeholderLarge from '../assets/images/prop-placeholder-lg.jpg';
import placeholderSmall from '../assets/images/prop-placeholder-sm.jpg';
import plusBlueIcon from '../assets/images/plus_blue.svg';
import locationPin from '../assets/images/location-pin.svg';
import bedIcon from '../assets/images/bed-duo.svg';
import showerIcon from '../assets/images/shower-duo.svg';
import liftIcon from '../assets/images/lift-duo.svg';
import clearCross from '../assets/images/clear-filter-cross.svg';
import filterCross from '../assets/images/filter-cross.svg';

const { useState, useRef } = React;

export const Projects = ({ showMessage }) => {
    const user = useSelector(state => state.user.user);

    return (
        <>
            <ProjectUpload showMessage={showMessage} />
        </>
    )
}