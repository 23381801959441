import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector, useDispatch } from "react-redux";
import { variables, validEmail } from '../Variables';

export const Testimonials = ({ showMessage }) => {
    const user = useSelector(state => state.user.user);
    const [selectedImages, setSelectedImages] = useState([]);
    const [projectName, setProjectName] = useState("");
    const [clientName, setClientName] = useState("");
    const [text, setText] = useState("");
    const [testimonials, setTestimonials] = useState([]);
    const [editingTestimonialId, setEditingTestimonialId] = useState(null);
    const [errors, setErrors] = useState([]);
    const [loading, setLoading] = useState(false);

    // Validation parameters
    const MAX_IMAGE_SIZE = 1 * 1024 * 1024; // 5 MB
    const MAX_IMAGE_COUNT = 1;
    const ALLOWED_TYPES = ['image/jpeg', 'image/png', 'image/gif'];

    useEffect(() => {
        fetchTestimonials();
    }, []);

    const fetchTestimonials = async () => {
        setLoading(true);
        const jsonconfig = {
            headers: {
                'Authorization': `Bearer ${user.tokenValue}`,
                'Content-Type': 'application/json'
            }
        };

        let apiUrl = variables.API_URL + 'Testimonials/GetTestimonials';

        await axios.get(apiUrl, jsonconfig) // ASP.NET Core API endpoint with header
            .then(response => {
                setTestimonials(response.data);
                setLoading(false);
            })
            .catch(error => {
                showMessage("error", 'Error fetching testimonials.');
                console.error('Error fetching testimonials:', error);
                setLoading(false);
            });
    };

    // Handle the image selection with validation
    const handleImageChange = (e) => {
        const files = Array.from(e.target.files);
        let validationErrors = [];

        if (files.length > MAX_IMAGE_COUNT) {
            validationErrors.push(`You can upload a maximum of ${MAX_IMAGE_COUNT} images.`);
        }

        const validFiles = files.filter((file) => {
            if (!ALLOWED_TYPES.includes(file.type)) {
                validationErrors.push(`${file.name} is not a supported file type.`);
                return false;
            }
            if (file.size > MAX_IMAGE_SIZE) {
                validationErrors.push(`${file.name} exceeds the maximum file size of 5MB.`);
                return false;
            }
            return true;
        });

        if (validationErrors.length > 0) {
            setErrors(validationErrors);
        } else {
            setErrors([]);
            setSelectedImages(validFiles);
        }
    };

    // Handle form submission for create/update
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (selectedImages.length === 0 && !editingTestimonialId) {
            setErrors(['Please select at least one valid image.']);
            return;
        }

        setLoading(true);

        const formData = new FormData();
        formData.append("projectName", projectName);
        formData.append("clientName", clientName);
        formData.append("text", text);
        selectedImages.forEach((image, index) => {
            formData.append(`clientImage`, image);
        });

        if (editingTestimonialId) {
            let apiUrl = variables.API_URL + 'Testimonials/UpdateTestimonial?id=' + editingTestimonialId;
            await axios.post(apiUrl, formData, {
                headers: {
                    'Authorization': `Bearer ${user.tokenValue}`,
                    'Content-Type': 'multipart/form-data'
                }
            }) // ASP.NET Core API endpoint with header
                .then(response => {
                    fetchTestimonials();
                    resetForm();
                    setLoading(false);
                    showMessage("success", 'Testimonial successfully updated.');
                })
                .catch(error => {
                    showMessage("error", 'There was an error updating your testimonial. Please try again.');
                    console.error('Error:', error);
                    setErrors(['There was an error updating your testimonial. Please try again.']);
                    setLoading(false);
                });
        }
        else {
            let apiUrl = variables.API_URL + 'Testimonials/CreateTestimonial';
            await axios.post(apiUrl, formData, {
                headers: {
                    'Authorization': `Bearer ${user.tokenValue}`,
                    'Content-Type': 'multipart/form-data'
                }
            }) // ASP.NET Core API endpoint with header
                .then(response => {
                    fetchTestimonials();
                    resetForm();
                    setLoading(false);
                    showMessage("success", 'Testimonial successfully uploaded.');
                })
                .catch(error => {
                    showMessage("error", 'There was an error saving your testimonial. Please try again.');
                    console.error('Error:', error);
                    setErrors(['There was an error saving your testimonial. Please try again.']);
                    setLoading(false);
                });
        }

    };

    const handleEdit = (testimonial) => {
        setEditingTestimonialId(testimonial.testimonialId);
        setProjectName(testimonial.projectName);
        setClientName(testimonial.clientName);
        setText(testimonial.text);
        setSelectedImages([]);
    };

    const handleDelete = async (testimonialId) => {
        if (window.confirm('Are you sure you want to delete this testimonial?')) {
            setLoading(true);
            const jsonconfig = {
                headers: {
                    'Authorization': `Bearer ${user.tokenValue}`,
                    'Content-Type': 'application/json'
                }
            };

            let apiUrl = variables.API_URL + 'Testimonials/DeleteTestimonial?id=' + testimonialId;

            await axios.post(apiUrl, {}, jsonconfig) // ASP.NET Core API endpoint with header
                .then(response => {
                    fetchTestimonials();
                    setLoading(false);
                    showMessage("success", 'Testimonial successfully deleted.');
                })
                .catch(error => {
                    showMessage("error", 'There was an error deleting the testimonial. Please try again.');
                    console.error('Error deleting testimonial:', error);
                    setErrors(['There was an error deleting the testimonial. Please try again.']);
                    setLoading(false);
                });

        }
    };

    const resetForm = () => {
        setProjectName("");
        setClientName("");
        setText("");
        setSelectedImages([]);
        setEditingTestimonialId(null);
        setErrors([]);
    };

    return (
        <>
            <div className="row">
                <div className="col-sm-12 px-4">
                    <div className="form-container mb-3 text-end">
                        <form onSubmit={handleSubmit}>
                            <div className="form-group row pt-2">
                                <label htmlFor="projectName" className="col-sm-4 col-lg-2 form-group-label mb-3">Project Name</label>
                                <div className="col-sm-8 col-lg-4 mb-3">
                                    <input
                                        type="text"
                                        value={projectName}
                                        onChange={(e) => setProjectName(e.target.value)}
                                        placeholder="Project Name"
                                        className="form-control"
                                        required
                                    />
                                </div>
                                <label htmlFor="clientName" className="col-sm-4 col-lg-2 form-group-label mb-3">Client Name</label>
                                <div className="col-sm-8 col-lg-4 mb-3">
                                    <input
                                        type="text"
                                        value={clientName}
                                        onChange={(e) => setClientName(e.target.value)}
                                        placeholder="Client Name"
                                        className="form-control"
                                        required
                                    />
                                </div>
                                <label htmlFor="officeId" className="col-sm-4 col-lg-2 form-group-label mb-3">Client Image</label>
                                <div className="col-sm-8 col-lg-4 mb-3">
                                    <input
                                        type="file"
                                        multiple
                                        onChange={handleImageChange}
                                        accept="image/*"
                                        className="form-control"
                                        required
                                    />
                                </div>
                                <label htmlFor="clientReview" className="col-sm-4 col-lg-2 form-group-label mb-3">Client Review</label>
                                <div className="col-sm-8 col-lg-4 mb-3">
                                    <textarea
                                        type="text"
                                        value={text}
                                        onChange={(e) => setText(e.target.value)}
                                        placeholder="Client Review"
                                        className="form-control"
                                        required
                                    />
                                </div>
                            </div>
                            <div className="mt-2">
                                <button type="submit" className={`site-btn ${editingTestimonialId ? "me-2" : ""}`} disabled={loading}>
                                    {loading ? 'Saving...' : editingTestimonialId ? 'Update Testimonial' : 'Upload Testimonial'}
                                </button>
                                {editingTestimonialId && <button className="site-btn" onClick={resetForm}>Cancel Edit</button>}
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12 px-4">
                    <span className="form-title">Testimonials List</span>
                    <div className="form-container mb-3">
                        {loading ? (
                            <p>Loading...</p>
                        ) : (

                            <table>
                                <thead>
                                    <tr>
                                        <th>Testimonial ID</th>
                                        <th>Project Name</th>
                                        <th>Client Name</th>
                                        <th>Testimonial Text</th>
                                        <th>Client Image</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        testimonials.map((project) => {
                                            let images = project.clientImage;
                                            let splittedPath = images.split(',');
                                            return (
                                                <tr key={project.testimonialId}>
                                                    <td className="col-1 text-center">{project.testimonialId}</td>
                                                    <td>{project.projectName}</td>
                                                    <td>{project.clientName}</td>
                                                    <td>{project.text}</td>
                                                    <td className="text-center">
                                                        {
                                                            splittedPath.length > 0 ? splittedPath.map(item => {
                                                                let image = variables.projectImgPath + item;
                                                                return (
                                                                    <img src={`${image}`} width="70" className="me-1" />
                                                                )
                                                            })
                                                                :
                                                                <></>
                                                        }
                                                    </td>
                                                    <td className="text-center"><button className="site-btn me-2" onClick={() => handleEdit(project)}>Edit</button>
                                                        <button className="site-btn btn-delete" onClick={() => handleDelete(project.testimonialId)}>Delete</button></td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

