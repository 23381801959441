import { Projects } from "./components/Projects";
import { Testimonials } from "./components/Testimonials";
import { Home } from "./components/Home";
import { faHouse, faCalendar, faHouseUser, faUsers, faCog } from '@fortawesome/free-solid-svg-icons';

const AppRoutes = [
    //{
    //    index: true,
    //    path: "/",
    //    element: <Home />,
    //    icon: "Home",
    //    name: "Home"
    //},
    {
        index: true,
        path: "/",
        element: <Projects />,
        icon: "project-management",
        name: "Projects"
    },
    {
        path: "/testimonials",
        element: <Testimonials />,
        icon: "testimonials",
        name: "Testimonials"
    }
];

export default AppRoutes;
