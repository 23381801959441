import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector, useDispatch } from "react-redux";
import { variables, validEmail } from '../Variables';

export const ProjectUpload = ({ showMessage }) => {
    const user = useSelector(state => state.user.user);
    const [selectedImages, setSelectedImages] = useState([]);
    const [projectName, setProjectName] = useState("");
    const [projectType, setProjectType] = useState("");
    const [projects, setProjects] = useState([]);
    const [editingProjectId, setEditingProjectId] = useState(null);
    const [errors, setErrors] = useState([]);
    const [loading, setLoading] = useState(false);

    // Validation parameters
    const MAX_IMAGE_SIZE = 2 * 1024 * 1024; // 5 MB
    const MAX_IMAGE_COUNT = 3;
    const ALLOWED_TYPES = ['image/jpeg', 'image/png', 'image/gif'];

    useEffect(() => {
        fetchProjects();
    }, []);

    const fetchProjects = async () => {
        setLoading(true);
        const jsonconfig = {
            headers: {
                'Authorization': `Bearer ${user.tokenValue}`,
                'Content-Type': 'application/json'
            }
        };

        let apiUrl = variables.API_URL + 'Projects/GetProjects';

        await axios.get(apiUrl, jsonconfig) // ASP.NET Core API endpoint with header
            .then(response => {
                setProjects(response.data);
                setLoading(false);
            })
            .catch(error => {
                showMessage("error", 'Error fetching projects.');
                console.error('Error fetching projects:', error);
                setLoading(false);
            });
    };

    // Handle the image selection with validation
    const handleImageChange = (e) => {
        const files = Array.from(e.target.files);
        let validationErrors = [];

        if (files.length > MAX_IMAGE_COUNT) {
            validationErrors.push(`You can upload a maximum of ${MAX_IMAGE_COUNT} images.`);
        }

        const validFiles = files.filter((file) => {
            if (!ALLOWED_TYPES.includes(file.type)) {
                validationErrors.push(`${file.name} is not a supported file type.`);
                return false;
            }
            if (file.size > MAX_IMAGE_SIZE) {
                validationErrors.push(`${file.name} exceeds the maximum file size of 5MB.`);
                return false;
            }
            return true;
        });

        if (validationErrors.length > 0) {
            setErrors(validationErrors);
        } else {
            setErrors([]);
            setSelectedImages(validFiles);
        }
    };

    // Handle form submission for create/update
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (selectedImages.length === 0 && !editingProjectId) {
            setErrors(['Please select at least one valid image.']);
            return;
        }

        setLoading(true);

        const formData = new FormData();
        formData.append("projectName", projectName);
        formData.append("projectType", projectType);
        selectedImages.forEach((image, index) => {
            formData.append(`images`, image);
        });

        if (editingProjectId) {
            let apiUrl = variables.API_URL + 'Projects/UpdateProject?id=' + editingProjectId;
            await axios.post(apiUrl, formData, {
                headers: {
                    'Authorization': `Bearer ${user.tokenValue}`,
                    'Content-Type': 'multipart/form-data'
                }
            }) // ASP.NET Core API endpoint with header
                .then(response => {
                    fetchProjects();
                    resetForm();
                    setLoading(false);
                    showMessage("success", 'Project successfully updated.');
                })
                .catch(error => {
                    showMessage("error", 'There was an error updating your project. Please try again.');
                    console.error('Error:', error);
                    setErrors(['There was an error updating your project. Please try again.']);
                    setLoading(false);
                });
        }
        else {
            let apiUrl = variables.API_URL + 'Projects/CreateProject';
            await axios.post(apiUrl, formData, {
                headers: {
                    'Authorization': `Bearer ${user.tokenValue}`,
                    'Content-Type': 'multipart/form-data'
                }
            }) // ASP.NET Core API endpoint with header
                .then(response => {
                    fetchProjects();
                    resetForm();
                    setLoading(false);
                    showMessage("success", 'Project successfully uploaded.');
                })
                .catch(error => {
                    showMessage("error", 'There was an error saving your project. Please try again.');
                    console.error('Error:', error);
                    setErrors(['There was an error saving your project. Please try again.']);
                    setLoading(false);
                });
        }

    };

    const handleEdit = (project) => {
        setEditingProjectId(project.projectId);
        setProjectName(project.projectName);
        setProjectType(project.projectType);
        setSelectedImages([]);
    };

    const handleDelete = async (projectId) => {
        if (window.confirm('Are you sure you want to delete this project?')) {
            setLoading(true);
            const jsonconfig = {
                headers: {
                    'Authorization': `Bearer ${user.tokenValue}`,
                    'Content-Type': 'application/json'
                }
            };

            let apiUrl = variables.API_URL + 'Projects/DeleteProject?id=' + projectId;

            await axios.post(apiUrl, {}, jsonconfig) // ASP.NET Core API endpoint with header
                .then(response => {
                    fetchProjects();
                    setLoading(false);
                    showMessage("success", 'Project successfully deleted.');
                })
                .catch(error => {
                    showMessage("error", 'There was an error deleting the project. Please try again.');
                    console.error('Error deleting project:', error);
                    setErrors(['There was an error deleting the project. Please try again.']);
                    setLoading(false);
                });

        }
    };

    const resetForm = () => {
        setProjectName("");
        setSelectedImages([]);
        setEditingProjectId(null);
        setErrors([]);
    };

    return (
        <>
            <div className="row">
                <div className="col-sm-12 px-4">
                    <div className="form-container mb-3 text-end">
                        <form onSubmit={handleSubmit}>
                            <div className="form-group row pt-2">
                                <label htmlFor="officeName" className="col-sm-4 col-lg-2 form-group-label mb-3">Project Name</label>
                                <div className="col-sm-8 col-lg-4 mb-3">
                                    <input
                                        type="text"
                                        value={projectName}
                                        onChange={(e) => setProjectName(e.target.value)}
                                        placeholder="Project Name"
                                        className="form-control"
                                        required
                                    />
                                </div>
                                <label htmlFor="officeId" className="col-sm-4 col-lg-2 form-group-label mb-3">Project Images</label>
                                <div className="col-sm-8 col-lg-4 mb-3">
                                    <input
                                        type="file"
                                        multiple
                                        onChange={handleImageChange}
                                        accept="image/*"
                                        className="form-control"
                                        required
                                    />
                                </div>
                                <label htmlFor="officeId" className="col-sm-4 col-lg-2 form-group-label mb-3">Project Type</label>
                                <div className="col-sm-8 col-lg-4 mb-3">
                                    <select className="form-select" name="service" value={projectType} onChange={(e) => setProjectType(e.target.value)} required>
                                        <option value="">--Select Project Type--</option>
                                        <option value="Roofing">
                                            Roofing
                                        </option>
                                        <option value="Waterproofing">
                                            Waterproofing
                                        </option>
                                        <option value="Remodeling">
                                            Remodeling
                                        </option>
                                        <option value="Sidewalk">
                                            Sidewalk
                                        </option>
                                        <option value="Restoration">
                                            Restoration
                                        </option>
                                        <option value="Masonry">
                                            Masonry
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div className="mt-2">
                                <button type="submit" className={`site-btn ${editingProjectId ? "me-2" : ""}`} disabled={loading}>
                                    {loading ? 'Saving...' : editingProjectId ? 'Update Project' : 'Upload Project'}
                                </button>
                                {editingProjectId && <button className="site-btn" onClick={resetForm}>Cancel Edit</button>}
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12 px-4">
                    <span className="form-title">Projects List</span>
                    <div className="form-container mb-3">
                        {loading ? (
                            <p>Loading...</p>
                        ) : (

                            <table>
                                <thead>
                                    <tr>
                                        <th>Project ID</th>
                                        <th>Project Name</th>
                                        <th>Project Type</th>
                                        <th>Project Images</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        projects.map((project) => {
                                            let images = project.projectImages;
                                            let splittedPath = images.split(',');
                                            return (
                                                <tr key={project.projectId}>
                                                    <td className="col-1 text-center">{project.projectId}</td>
                                                    <td>{project.projectName}</td>
                                                    <td>{project.projectType}</td>
                                                    <td className="text-center">
                                                        {
                                                            splittedPath.length > 0 ? splittedPath.map(item => {
                                                                let image = variables.projectImgPath+item;
                                                                return (
                                                                    <img src={`${image}`} width="70" className="me-1" />
                                                                )
                                                            })
                                                                :
                                                            <></>
                                                        }
                                                    </td>
                                                    <td className="text-center"><button className="site-btn me-2" onClick={() => handleEdit(project)}>Edit</button>
                                                        <button className="site-btn btn-delete" onClick={() => handleDelete(project.projectId)}>Delete</button></td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

