import * as React from "react";
import * as ReactDOM from "react-dom";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { variables, validEmail } from '../Variables';
import { useDispatch } from "react-redux";
import { login } from "../features/userSlice";
import { clearSidebarCollapsed } from "../features/SidebarCollapseSlice";

import mindfreeLogo from '../assets/images/logo.png';
import tickVector from '../assets/images/Vector.svg';
import eyeOff from '../assets/images/eye-off.svg';
import eyeOpen from '../assets/images/eye-open.svg';

const { useState, useEffect } = React;

export const Login = () => {
    const [emailMessage, setEmailMessage] = useState("");
    const [passwordMessage, setPasswordMessage] = useState("");
    const [showhideTickIcon, setShowhideTickIcon] = useState("d-none");
    const [eyeIcon, setEyeIcon] = useState(eyeOff);
    const [passFieldType, setPassFieldType] = useState("password");
    const [message, setMessage] = useState("");
    const [messageType, setMessageType] = useState("");
    const [display, setDisplay] = useState("d-none");
    const [clientToken, setClientToken] = useState("");

    const dispatch = useDispatch();

    const showMessage = (type, message) => {
        setMessageType(type);
        setMessage(message);
        setDisplay("d-block");
        const timer = setTimeout(() => {
            setMessageType("");
            setMessage("");
            setDisplay("d-none");

            return () => clearTimeout(timer);
        }, [3000]);
    }

    const handleSubmit = (e) => {
        e.target.setAttribute("disabled", true);
        e.target.style.cursor = "progress";
        document.querySelector("body").style.cursor = "progress";
        let email = document.getElementById("userEmail");
        let pass = document.getElementById("userPassword");
        let isFieldEmpty = false;
        dispatch(clearSidebarCollapsed());

        let user = {
            UserName: email.value,
            Email: email.value,
            Password: pass.value,
            Status: true
        }
        let errorMessage = [];

        if (user.UserName == "") {
            email.classList.add("empty");
            isFieldEmpty = true;
            setEmailMessage("Enter user name");
        }

        if (user.Password == "") {
            pass.classList.add("empty");
            isFieldEmpty = true;
            setPasswordMessage("Enter your password");
        }

        if (isFieldEmpty == true) {
            e.target.removeAttribute("disabled");
            e.target.style.cursor = "pointer";
            document.querySelector("body").style.cursor = "default";
            return;
        }

        let url = variables.API_URL + `User/AuthenticateUser`;

        const jsonconfig = {
            headers: {
                'Content-Type': 'application/json'
            }
        };
        axios.post(url, JSON.stringify(user), jsonconfig) // ASP.NET Core API endpoint with header
            .then(response => {
                if (response.data == "Account doesn't exist with this email" || response.data == "Wrong Password") {
                    showMessage("error", response.data);
                    e.target.removeAttribute("disabled");
                    e.target.style.cursor = "";
                    document.querySelector("body").style.cursor = "";
                    return
                } else {
                    dispatch(
                        login(response.data)
                    );
                }
                e.target.removeAttribute("disabled");
                e.target.style.cursor = "";
                document.querySelector("body").style.cursor = "";
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                e.target.removeAttribute("disabled");
                e.target.style.cursor = "";
                document.querySelector("body").style.cursor = "";
            });
    };

    const resetErrorFieldStyle = (e) => {
        if (e.target.classList.contains("empty")) {
            e.target.classList.remove("empty");
        }

        if (e.target.id == "userEmail") {
            setEmailMessage("");
        }
        else {
            setPasswordMessage("");
        }
    }

    const changeHandlerLoginField = (e) => {
        if (e.target.id == "userEmail" && e.target.value != "") {
            setShowhideTickIcon("");
        }
        else if (e.target.id == "userEmail" && e.target.value == "") {
                setShowhideTickIcon("d-none");
            }
    }

    const handlerShowPassword = (e) => {
        if (passFieldType == "password") {
            setEyeIcon(eyeOpen);
            setPassFieldType("text");
        }
        else {
            setEyeIcon(eyeOff);
            setPassFieldType("password");
        }
    }

    const handleEnter = (event) => {
        if (event.key === "Enter") {
            handleSubmit(event);
        }

    }

    return (
        <>

            <div className={`login-message-box ${messageType == "error" ? "login-error-box" : ""} ${display}`}>
                <div className="login-message-container">
                    <p className="message-box-t mb-0">{message}</p>
                </div>
            </div>
            <div className="d-flex justify-content-center">
                <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-5">
                    <div className="login-form-container">
                        <div className="col-12">
                            <div className="img-container mb-4 pb-3 col-5 m-auto">
                                <img src={mindfreeLogo} alt="user vector" style={{ width: "100%" }} />
                            </div>
                            <div className="text-center mb-4 pb-2">
                                <span className="login-title">Login</span>
                            </div>
                            <div className="form-group mb-3 pb-1 position-relative">
                                <label htmlFor="userEmail" className="col-sm-12 form-group-label">User Name<span>*</span></label>
                                <div className="col-sm-12 position-relative">
                                    <input type="email" id="userEmail" className="form-control"
                                        placeholder="User Name" onFocus={resetErrorFieldStyle} onChange={changeHandlerLoginField} />
                                    <img src={tickVector} className={`input-field-icons ` + showhideTickIcon } alt="tick vector" />
                                </div>
                                <small className="text-danger position-absolute fs-small login-field-error">{emailMessage}</small>
                            </div>

                            <div className="form-group mb-3 pb-1 position-relative">
                                <label htmlFor="password" className="col-sm-12 form-group-label">Password<span>*</span></label>
                                <div className="col-sm-12 position-relative">
                                    <input type={passFieldType} id="userPassword" className="form-control" onChange={changeHandlerLoginField}
                                        placeholder="Password" onFocus={resetErrorFieldStyle} onKeyPress={handleEnter} />
                                    <img src={eyeIcon} className="input-field-icons eye-icon" alt="eye vector" onClick={handlerShowPassword} />
                                </div>
                                <small className="text-danger position-absolute fs-small login-field-error">{passwordMessage}</small>
                            </div>
                            <div className="text-center mt-4 mb-4">
                                <button type="button" className="site-btn site-btn-padd w-100" onClick={handleSubmit} >Login</button>
                            </div>
                        </div>
                    </div>
                    <div className="text-center">
                        <p className="rights-text">© 2024 Homex Builders Corp. Alright reserved.</p>
                    </div>
                </div>
            </div>
        </>
    )
}