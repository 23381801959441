import { configureStore, combineReducers } from "@reduxjs/toolkit";
import userReducer from '../features/userSlice';
import SidebarCollapseReducer from '../features/SidebarCollapseSlice';
import storage from 'redux-persist/lib/storage';
import {
    persistReducer, persistStore,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER } from 'redux-persist';
import storageSession from 'redux-persist/es/storage/session';

const persistConfig = {
    key: 'root',
    storage: storageSession,
}

const rootReducer = combineReducers({
    user: userReducer,
    isSideBarCollapsed: SidebarCollapseReducer
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        })
})

export const persistor = persistStore(store);